import axios from "axios";
import router from "@/router";

export const api = axios.create({
  baseURL: "https://checkout2.loguspay.com/api",
  // baseURL: "http://localhost:4000",
  timeout: 20000,
});

// request
export async function request(method, endpoint, data, headers = {}) {
  return await new Promise((resolve, reject) => {
    try {
      api[method](endpoint, data, { headers: headers })
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err.response);
        });
    } catch (err) {
      return reject(err.response);
    }
  });
}

// interceptor
api.interceptors.response.use(
  (res) => responseSuccess(res),
  (err) => responseFailed(err)
);

const responseSuccess = (res) => Promise.resolve(res);

const responseFailed = async (err) => {
  // Server error (5XX)
  if (err.response.status >= 500) {
    await router.push({
      path: "/checkout",
    });
  }

  return Promise.reject(err);
};
